import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router'
import Card from '../../Common/Card'
import Grid from '@mui/material/Grid'
import styles from './../NotesContainer.module.scss'
import NoteEditor from './../NoteEditor'
import { currentWorkFlow, secondaryWorkFlow } from './../../../helpers/helpers'
import cn from 'classnames'
import { WorkflowTypes } from '@common/interfaces/notes'
import { IAttachment } from '@common/interfaces/notes'

interface IProps {
  addNote: (params: object) => Promise<void>
  pathname: string
  isLoading: boolean
  parentId?: string
  placeholder?: string
  hideReply?: () => void
  editorState?: any
  onEditorStateChange?: (editorState: any) => void
  disabled?: boolean
  hideExpandModal?: () => void
  isExpandModal?: boolean
  noteEditorFlex?: boolean
  isComment?: boolean
  isDiligence?: boolean
  listNotes?: (data: any) => void
  shouldFocusEditorInput?: boolean
}

const AddNote = ({
  addNote,
  pathname,
  placeholder,
  parentId = null,
  hideReply,
  editorState,
  onEditorStateChange,
  disabled = false,
  hideExpandModal,
  isExpandModal = false,
  noteEditorFlex = false,
  isComment = false,
  isDiligence = false,
  listNotes,
  shouldFocusEditorInput,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const [attachments, setAttachments] = useState<IAttachment[]>([])

  const handleSubmit = useCallback(
    async (note: object) => {
      let salesforceField: string = ''
      const workflow = currentWorkFlow(pathname)
      if (workflow === WorkflowTypes.opsFlow) {
        salesforceField = secondaryWorkFlow(pathname)
      }

      await addNote({
        workflow,
        note,
        id,
        parentId,
        salesforceField,
        attachments,
        skipLoader: isDiligence,
      })

      isDiligence && listNotes && (await listNotes({ isDueDiligence: true }))
    },
    [addNote, id, pathname, parentId, attachments, isDiligence, listNotes],
  )

  return (
    <Card
      noHeaderMargin
      className={cn({
        [styles.comment]: parentId !== null,
        [styles.addNoteCard]: parentId === null,
        [styles.addNoteWithAttachment]: !!attachments.length,
      })}
      withBorder={false}
    >
      <Grid>
        <Grid item xs={12}>
          <NoteEditor
            key={`note-editor-${parentId || 'newNote'}`}
            className={cn({
              [styles.noteEditor]: parentId === null,
              [styles.commentEditor]: parentId !== null,
            })}
            editorClassName={cn(styles.editor, {
              [styles.noteEditorEditor]: parentId === null || !!attachments.length,
              [styles.commentEditorEditor]: parentId !== null,
            })}
            editorFlex={noteEditorFlex}
            handleSubmit={handleSubmit}
            placeholder={placeholder}
            setAttachments={setAttachments}
            attachments={attachments}
            withToolbar
            isToolbarStatic={parentId !== null && !!hideReply}
            hideReply={hideReply}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            disabled={disabled}
            hideExpandModal={hideExpandModal}
            isExpanded={isExpandModal}
            isComment={isComment}
            isAddNote
            shouldFocusEditorInput={shouldFocusEditorInput}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default AddNote
