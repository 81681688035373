import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import cn from 'classnames'

import styles from './DueDiligenceClientDocumentRequestModal.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as WarningIcon } from '@assets/images/warning-icon-outline.svg'
import { ReactComponent as CheveronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as CheveronDown } from '@assets/images/down-chevron.svg'
import {
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES,
  DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD,
  DueDiligenceDocumentRequestTypes,
  IDueDiligenceDocumentRequest,
  IDueDiligenceDocumentRequestComment,
} from '@common/interfaces/dueDiligence'
import DueDiligenceSideContainer from '../DueDiligenceSideContainer'
import WarningModal from '../WarningModal'
import UploadFileManagement from '../Common/UploadFileManagement'
import { DEFAULT_MAX_FILE_SIZE } from '../../constants/common'
import { IFile } from '@common/interfaces/box'
import { humanReadableFileSize } from '@common/helpers/helpers'
import {
  DueDiligenceDocumentRequestAddComment,
  DueDiligenceDocumentRequestComment,
} from '../DueDiligenceCommentsModal/DueDiligenceCommentsModal'

interface IProps {
  isSaving: boolean
  readOnly?: boolean
  documentRequest: IDueDiligenceDocumentRequest
  handleSaveComment: (data: Partial<any>) => Promise<any>
  handleDeleteComment: (id: string) => Promise<any>
  handleUploadFiles: (files: File[]) => void
  handleDeleteFiles: (ids: string[]) => Promise<any>
  handleViewSample: (id: string) => void
  handleClose: () => void
  putNotification: (notification: object) => void
  userId: string
}

const DueDiligenceClientDocumentRequestModal = ({
  isSaving,
  readOnly = false,
  documentRequest,
  handleDeleteComment,
  handleUploadFiles,
  handleDeleteFiles,
  handleViewSample,
  handleClose,
  putNotification,
  handleSaveComment,
  userId,
}: IProps) => {
  const [isUploading, setIsUploading] = useState(false)
  const documentsWrapperRef = useRef(null)
  const commentsWrapperRef = useRef(null)

  const [isDocumentsExpanded, setIsDocumentsExpanded] = useState(false)
  const [isCommentsExpanded, setIsCommentsExpanded] = useState(true)
  const [commentForDelete, setCommentForDelete] =
    useState<IDueDiligenceDocumentRequestComment>(null)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [isDeleting, setIsDeleting] = useState(false)

  const toggleDocuments = useCallback(() => {
    setIsDocumentsExpanded((isExpanded) => !isExpanded)
    setIsCommentsExpanded(false)
  }, [])

  const toggleComments = useCallback(() => {
    setIsCommentsExpanded((isExpanded) => !isExpanded)
    setIsDocumentsExpanded(false)
  }, [])

  const scrollSectionsToBottom = useCallback(() => {
    setTimeout(() => {
      if (commentsWrapperRef.current) {
        commentsWrapperRef.current.scrollTop = commentsWrapperRef.current.scrollHeight
      }
    }, 400)
    setTimeout(() => {
      if (documentsWrapperRef.current) {
        documentsWrapperRef.current.scrollTop = documentsWrapperRef.current.scrollHeight
      }
    }, 400)
  }, [])

  useEffect(() => {
    if (isDocumentsExpanded) {
      scrollSectionsToBottom()
    }
  }, [isDocumentsExpanded, scrollSectionsToBottom])

  useEffect(() => {
    if (isCommentsExpanded) {
      scrollSectionsToBottom()
    }
  }, [isCommentsExpanded, scrollSectionsToBottom])

  useEffect(() => {
    scrollSectionsToBottom()
  }, [documentRequest.files, documentRequest.comments, scrollSectionsToBottom])

  useEffect(() => {
    setTimeout(() => {
      scrollSectionsToBottom()
    }, 400)
  }, [scrollSectionsToBottom])

  const modifiedOnUpload = useCallback(
    async (files: File[]) => {
      !isDocumentsExpanded && setIsDocumentsExpanded(true)
      setIsCommentsExpanded(false)
      setIsUploading(true)
      await handleUploadFiles(files)
      setIsUploading(false)
    },
    [handleUploadFiles, isDocumentsExpanded, setIsDocumentsExpanded, setIsCommentsExpanded],
  )

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: modifiedOnUpload,
    onDropRejected: handleFileRejection,
  })

  const onDeleteFiles = useCallback(
    (indexes: number[]) => {
      const files = documentRequest?.files || []
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [documentRequest],
  )

  const handleDeleteFilesConfirm = useCallback(async () => {
    setIsDeleting(true)
    await handleDeleteFiles(filesForDelete.map((file) => file.id))
    setFilesForDelete([])
    setIsDeleting(false)
  }, [handleDeleteFiles, filesForDelete])

  const handleDeleteFilesCancel = useCallback(async () => {
    setFilesForDelete([])
  }, [])

  const handleViewSampleClick = useCallback(() => {
    if (DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(documentRequest.type.type)) {
      handleViewSample(documentRequest.id)
    }
  }, [handleViewSample, documentRequest])

  const isCustom = useMemo(
    () => DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD.includes(documentRequest.type.type),
    [documentRequest],
  )

  const handleCancel = useCallback(() => {
    if (!isUploading) {
      handleClose()
    }
  }, [isUploading, handleClose])

  const handleAddComment = useCallback(
    async (commentText: string) => {
      !isCommentsExpanded && toggleComments()
      const result = await handleSaveComment({ comment: commentText })
      return result
    },
    [handleSaveComment, isCommentsExpanded, toggleComments],
  )

  const handleEditComment = useCallback(
    async (comment: IDueDiligenceDocumentRequestComment) => handleSaveComment(comment),
    [handleSaveComment],
  )

  const handleDeleteCommentItem = useCallback((comment: IDueDiligenceDocumentRequestComment) => {
    setCommentForDelete(comment)
  }, [])

  const handleDeleteCommentConfirm = useCallback(async () => {
    await handleDeleteComment(commentForDelete.id)
    setCommentForDelete(null)
  }, [commentForDelete, handleDeleteComment])

  const handleDeleteCommentCancel = useCallback(() => {
    setCommentForDelete(null)
  }, [])

  return (
    <DueDiligenceSideContainer open onCancel={handleCancel} title={documentRequest.type.name}>
      {!isCustom && (
        <>
          {!readOnly && (
            <div className={styles.uploadHeader}>
              Upload Document
              {DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES.includes(documentRequest.type.type) && (
                <Tooltip placement="top" title="View Sample">
                  <div onClick={handleViewSampleClick} className={styles.templateLink}>
                    View Sample
                  </div>
                </Tooltip>
              )}
            </div>
          )}

          {documentRequest.type.helperText && (
            <div className={genericSs.warningMessage}>{documentRequest.type.helperText}</div>
          )}
          {documentRequest.type.fileType && (
            <div className={genericSs.warningMessage}>
              {documentRequest.type.fileType} format is required for this document
            </div>
          )}

          {!readOnly && (
            <UploadFileManagement
              className={styles.uploadSection}
              files={documentRequest.files}
              onDelete={onDeleteFiles}
              isDragAccept={isDragAccept}
              isDragReject={isDragReject}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              open={open}
              handleDelete={onDeleteFiles}
              dropzoneText="Drop files here or "
              showFiles={false}
            />
          )}
        </>
      )}

      <div className={styles.documentsAndCommentsSection}>
        {isCustom ? (
          <>
            <div className={styles.documentsHeader}>Details</div>
            <div className={cn(styles.documentsSection, styles.documentsSectionExpanded)}>
              {documentRequest.type.type === DueDiligenceDocumentRequestTypes.BankTransactions ? (
                <>
                <div>
                  Please provide read-only bank account access to{' '}
                  <a target="_blank" rel="noopener noreferrer" href="mailto:ops@dwightfund.com">
                    ops@dwightfund.com
                  </a>{' '}
                  for each of your business bank accounts.
                </div>
                <div>
                  Include Dwight Funding's phone number: (646)-960-5105. If prompted to input a first and last name, you can use "Dwight" and "Funding", respectively.
                </div>
                </>
              ) : (
                <div>
                  Please provide vendor portal and/or Shopify access to{' '}
                  <a target="_blank" rel="noopener noreferrer" href="mailto:ops@dwightfund.com">
                    ops@dwightfund.com
                  </a>{' '}
                  for each of your key accounts.
                </div>
              )}
              <div>
                Please e-mail the details to{' '}
                <a target="_blank" rel="noopener noreferrer" href="mailto:ops@dwightfund.com">
                  ops@dwightfund.com
                </a>{' '}
                and reach out to our team should you have any questions or concerns!
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.documentsHeader} onClick={toggleDocuments}>
              <span>
                Documents
                {documentRequest.files.length > 0 && <span> ({documentRequest.files.length})</span>}
              </span>
              {isDocumentsExpanded ? <CheveronUp /> : <CheveronDown />}
            </div>

            <div
              ref={documentsWrapperRef}
              className={cn(styles.documentsSection, {
                [styles.documentsSectionExpanded]: isDocumentsExpanded,
              })}
            >
              {documentRequest.files.length > 0 ? (
                <UploadFileManagement
                  files={documentRequest.files}
                  onDelete={onDeleteFiles}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  open={open}
                  handleDelete={onDeleteFiles}
                  dropzoneText="Drop files here or "
                  showDropzone={false}
                  readOnly={readOnly}
                />
              ) : (
                <div className={styles.commentsEmpty}>
                  <WarningIcon />
                  There are no documents uploaded
                </div>
              )}
            </div>
          </>
        )}

        <div className={styles.commentsHeader} onClick={toggleComments}>
          <span>
            {'Comments'}
            {documentRequest.comments.length > 0 && (
              <span> ({documentRequest.comments.length})</span>
            )}
          </span>
          {isCommentsExpanded ? <CheveronUp /> : <CheveronDown />}
        </div>

        <div
          ref={commentsWrapperRef}
          className={cn(styles.commentsWrapper, {
            [styles.commentsWrapperExpanded]: isCommentsExpanded,
          })}
        >
          {documentRequest.comments.length > 0 ? (
            documentRequest.comments.map((comment) => (
              <DueDiligenceDocumentRequestComment
                key={comment.id}
                comment={comment}
                isOwn={comment.userId === userId}
                isSaving={isSaving}
                isEditable={!readOnly}
                onEdit={handleEditComment}
                onDelete={handleDeleteCommentItem}
              />
            ))
          ) : (
            <div className={styles.commentsEmpty}>
              <WarningIcon />
              No comments
            </div>
          )}
        </div>
      </div>
      <DueDiligenceDocumentRequestAddComment handleAddComment={handleAddComment} />
      {filesForDelete.length > 0 && (
        <WarningModal
          warningMessage={
            filesForDelete.length > 1
              ? 'Deleting these files will be permanent.'
              : 'Deleting this file will be permanent.'
          }
          onConfirm={handleDeleteFilesConfirm}
          onCancel={handleDeleteFilesCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isDeleting}
          disabled={isDeleting}
        />
      )}

      {commentForDelete && (
        <WarningModal
          warningMessage="Deleting this comment will be permanent."
          onConfirm={handleDeleteCommentConfirm}
          onCancel={handleDeleteCommentCancel}
          confirmText="Yes, proceed"
          cancelText="Cancel"
          isLoading={isSaving}
          disabled={isSaving}
        />
      )}
    </DueDiligenceSideContainer>
  )
}

export default DueDiligenceClientDocumentRequestModal
