import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './BBCIneligibleARDetails.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import { IARIneligibleStatsDetails, IClientCollateral } from '@common/interfaces/bbc'
import { debounceEventHandler, formatDate, formatPrice, calculateGrowthPercentage } from '../../helpers/helpers'
import BBCIneligibleARDetailsInvoices from '../BBCIneligibleARDetailsInvoices'
import { AR_INELIGIBLE_STATS_DETAILS_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'
import GrowthDisplay from '../Common/GrowthDisplay'

interface IProps {
  arDetailStats: IARIneligibleStatsDetails[]
  clientCollaterals: IClientCollateral[]
  listArIneligibleStatsDetails: (id: string, data: object) => void
  filters: any
  label: string
  displayGrowth?: boolean
}

const BBCIneligibleARDetails = ({
  arDetailStats,
  clientCollaterals,
  listArIneligibleStatsDetails,
  filters,
  label,
  displayGrowth = false,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [expanded, setExpanded] = useState([])

  const {
    handleSelectRow,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
  } = useTable({
    tableId: 'ar-ineligible-stats-details',
    sortDefault: {
      field: 'debtor',
      direction: 'ASC',
    },
  })

  const debounceListArIneligibleStatsDetails = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listArIneligibleStatsDetails(id, {
          ...data,
          label,
          nestedRows: {
            keys: ['label'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, listArIneligibleStatsDetails, label],
  )

  useEffect(() => {
    !arDetailStats &&
      debounceListArIneligibleStatsDetails({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails, arDetailStats])

  useEffect(() => {
    debounceListArIneligibleStatsDetails({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails])

  const loadMore = useCallback(() => {
    debounceListArIneligibleStatsDetails({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(arDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails, arDetailStats])

  const totalRow = useSummaryRow(arDetailStats, activeItems, {
    sumFields: ['value_0', 'value_1', 'value_2', 'value_3', 'value_4'],
  })

  const totalCount = useMemo(() => arDetailStats?.[0]?.totalCount, [arDetailStats])

  const handleExpand = useCallback((debtor: string) => {
    setExpanded((values) =>
      values.includes(debtor) ? values.filter((item) => item !== debtor) : [...values, debtor],
    )
  }, [])

  const filtersConfig = useMemo(
    () =>
      AR_INELIGIBLE_STATS_DETAILS_FILTERS_CONFIG.filter(
        (filter) =>
          !filter.field.startsWith('value_') ||
          !!clientCollaterals[parseInt(filter.field.replace('value_', ''))],
      ).map((filter) => {
        if (!filter.field.startsWith('value_') || filter.field === 'value_0') {
          return filter
        }

        const index = +filter.field.replace('value_', '')
        return {
          ...filter,
          title: formatDate(clientCollaterals[index].recordDate),
        }
      }),
    [clientCollaterals],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          >
            <TableCell />
          </TableFiltersRow>
        </TableHead>
        <TableBody id={`scrollableIneligibleARDetails-${label}`} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={clientCollaterals.length + 1} />
          ) : (
            <InfiniteScroll
              dataLength={arDetailStats?.length || 0}
              next={loadMore}
              hasMore={arDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={clientCollaterals.length + 2} rowsCount={1} />}
              scrollableTarget={`scrollableIneligibleARDetails-${label}`}
            >
              {arDetailStats?.map((row, index) => {
                const { debtor, rows, ...rest } = row
                const isExpanded = expanded.includes(debtor)
                const hasChild =
                  ['over_90DaysAndCreditsPastDue', 'ineligibleDebtors'].includes(label) &&
                  Object.values(rest).filter(Boolean).length > 0

                return (
                  <React.Fragment key={debtor}>
                    <TableRow
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        {hasChild && (
                          <ExpandDetailIcon
                            onClick={() => handleExpand(debtor)}
                            isExpanded={isExpanded}
                          />
                        )}
                      </TableCell>
                      <TableCell className={cn(genericSs.tableTextLeft, genericSs.textCapitalize)}>
                        {debtor}
                      </TableCell>
                      {clientCollaterals.map((clientCollateral, index) => {
                        let growthPercent;
                        if (displayGrowth && index < clientCollaterals.length - 1) {
                          const currentValue = Number(rest[`value_${index}` as keyof typeof rest]);
                          const nextValue = Number(rest[`value_${index + 1}` as keyof typeof rest]);
                          growthPercent = calculateGrowthPercentage(currentValue, nextValue);
                        }
                        
                        return (
                          <TableCell key={clientCollateral.id} className={cn(genericSs.tableTextRight, {
                            [genericSs.cellWithGrowthChip]: displayGrowth,
                          })}>
                            $ {formatPrice(rest[`value_${index}` as keyof typeof rest])}
                            {displayGrowth && growthPercent !== undefined && (
                              <GrowthDisplay value={growthPercent} />
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    {isExpanded && (
                      <TableRow>
                        <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                          <BBCIneligibleARDetailsInvoices
                            arDetailStats={rows}
                            filters={filters}
                            label={label}
                            debtor={debtor}
                            displayGrowth={displayGrowth}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems} addColumn>
            {clientCollaterals.map((_, index) => (
              <TableCell key={index} className={genericSs.tableTextRight}>
                $ {formatPrice(totalRow?.[`value_${index}` as keyof IARIneligibleStatsDetails])}
              </TableCell>
            ))}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCIneligibleARDetails
