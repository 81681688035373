import React, { useMemo } from 'react'
import cn from 'classnames'
import styles from './GrowthDisplay.module.scss'

interface GrowthDisplayProps {
  value?: number;
}

const GrowthDisplay = ({ value }: GrowthDisplayProps) => {
  const isPositive = useMemo(() => value > 0, [value])

  const displayValue = useMemo(() => {
    if (!value && value !== 0) return '';
    
    const absValue = Math.abs(value * 100)

    return absValue >= 10000
      ? '9999+'
      : absValue >= 100
      ? Math.round(absValue)
      : absValue >= 10
      ? absValue.toFixed(1)
      : absValue.toFixed(2)
  }, [value])

  if (!value && value !== 0) {
    return null
  }

  return (
    <span
      className={cn(styles.dataGrowth, {
        [styles.dataGrowthPositive]: isPositive,
      })}
    >
      {displayValue}%
    </span>
  )
}

export default GrowthDisplay
