import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'
export const prefix = 'dueDiligence'

export const GET_STATS_REQUEST = `${prefix}/GET_STATS_REQUEST`
export const GET_STATS_SUCCESS = `${prefix}/GET_STATS_SUCCESS`
export const GET_STATS_FAILURE = `${prefix}/GET_STATS_FAILURE`

export const getStats: CommonPromiseAction = () => ({
  type: [GET_STATS_REQUEST, GET_STATS_SUCCESS, GET_STATS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.getStats(),
})

export const LIST_PROSPECTS_REQUEST = `${prefix}/LIST_PROSPECTS_REQUEST`
export const LIST_PROSPECTS_SUCCESS = `${prefix}/LIST_PROSPECTS_SUCCESS`
export const LIST_PROSPECTS_FAILURE = `${prefix}/LIST_PROSPECTS_FAILURE`

export const listProspects: CommonPromiseAction = () => ({
  type: [LIST_PROSPECTS_REQUEST, LIST_PROSPECTS_SUCCESS, LIST_PROSPECTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.listProspects(),
})

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`

export const list: CommonPromiseAction = (params: object) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.dueDiligence.list(params),
  params,
})

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`

export const show: CommonPromiseAction = (id: string) => ({
  type: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: (api: IApi) => api.dueDiligence.show(id),
})

export const HIDE = `${prefix}/HIDE`

export const hide: CommonPromiseAction = () => ({
  type: HIDE,
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const update: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.dueDiligence.update(id, data),
})

export const SHOW_REPORTING_REQUEST = `${prefix}/SHOW_REPORTING_REQUEST`
export const SHOW_REPORTING_SUCCESS = `${prefix}/SHOW_REPORTING_SUCCESS`
export const SHOW_REPORTING_FAILURE = `${prefix}/SHOW_REPORTING_FAILURE`

export const showReporting: CommonPromiseAction = (id: string, params: object = {}) => ({
  type: [SHOW_REPORTING_REQUEST, SHOW_REPORTING_SUCCESS, SHOW_REPORTING_FAILURE],
  promise: (api: IApi) => api.dueDiligence.showReporting(id, params),
  params,
})

export const UPDATE_REPORTING_REQUEST = `${prefix}/UPDATE_REPORTING_REQUEST`
export const UPDATE_REPORTING_SUCCESS = `${prefix}/UPDATE_REPORTING_SUCCESS`
export const UPDATE_REPORTING_FAILURE = `${prefix}/UPDATE_REPORTING_FAILURE`

export const updateReporting: CommonPromiseAction = (id: string, type: string, data: object) => ({
  type: [UPDATE_REPORTING_REQUEST, UPDATE_REPORTING_SUCCESS, UPDATE_REPORTING_FAILURE],
  promise: (api: IApi) => api.dueDiligence.updateReporting(id, type, data),
})

export const SHOW_DOCUMENTS_REQUEST = `${prefix}/SHOW_DOCUMENTS_REQUEST`
export const SHOW_DOCUMENTS_SUCCESS = `${prefix}/SHOW_DOCUMENTS_SUCCESS`
export const SHOW_DOCUMENTS_FAILURE = `${prefix}/SHOW_DOCUMENTS_FAILURE`

export const showDocuments: CommonPromiseAction = (
  id: string,
  type: string,
  params: object = {},
) => ({
  type: [SHOW_DOCUMENTS_REQUEST, SHOW_DOCUMENTS_SUCCESS, SHOW_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.showDocuments(id, type, params),
  params,
})

export const HIDE_DOCUMENTS = `${prefix}/HIDE_DOCUMENTS`

export const hideDocuments: CommonPromiseAction = () => ({
  type: HIDE_DOCUMENTS,
})

export const PROCESS_DOCUMENTS_REQUEST = `${prefix}/PROCESS_DOCUMENTS_REQUEST`
export const PROCESS_DOCUMENTS_SUCCESS = `${prefix}/PROCESS_DOCUMENTS_SUCCESS`
export const PROCESS_DOCUMENTS_FAILURE = `${prefix}/PROCESS_DOCUMENTS_FAILURE`

export const processDocuments: CommonPromiseAction = (id: string, type: string, data: object) => ({
  type: [PROCESS_DOCUMENTS_REQUEST, PROCESS_DOCUMENTS_SUCCESS, PROCESS_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.processDocuments(id, type, data),
  params: {
    id,
    type,
  },
})

export const UPLOAD_DOCUMENTS_REQUEST = `${prefix}/UPLOAD_DOCUMENTS_REQUEST`
export const UPLOAD_DOCUMENTS_SUCCESS = `${prefix}/UPLOAD_DOCUMENTS_SUCCESS`
export const UPLOAD_DOCUMENTS_FAILURE = `${prefix}/UPLOAD_DOCUMENTS_FAILURE`

export const uploadDocuments: CommonPromiseAction = (id: string, type: string, data: FormData) => ({
  type: [UPLOAD_DOCUMENTS_REQUEST, UPLOAD_DOCUMENTS_SUCCESS, UPLOAD_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.uploadDocuments(id, type, data),
  params: {
    id,
    type,
  },
})

export const UPDATE_DOCUMENT_REQUEST = `${prefix}/UPDATE_DOCUMENT_REQUEST`
export const UPDATE_DOCUMENT_SUCCESS = `${prefix}/UPDATE_DOCUMENT_SUCCESS`
export const UPDATE_DOCUMENT_FAILURE = `${prefix}/UPDATE_DOCUMENT_FAILURE`

export const updateDocument: CommonPromiseAction = (
  id: string,
  type: string,
  flowId: string,
  data: object,
) => ({
  type: [UPDATE_DOCUMENT_REQUEST, UPDATE_DOCUMENT_SUCCESS, UPDATE_DOCUMENT_FAILURE],
  promise: (api: IApi) => api.dueDiligence.updateDocument(id, type, flowId, data),
  params: {
    id,
    type,
  },
})

export const UNPROCESS_DOCUMENT_REQUEST = `${prefix}/UPDATE_CLEAR_DOCUMENT_DATA_REQUEST`
export const UNPROCESS_DOCUMENT_SUCCESS = `${prefix}/UPDATE_CLEAR_DOCUMENT_DATA_SUCCESS`
export const UNPROCESS_DOCUMENT_FAILURE = `${prefix}/UPDATE_CLEAR_DOCUMENT_DATA_FAILURE`

export const unprocessDocument: CommonPromiseAction = (
  id: string,
  type: string,
  flowId: string,
) => ({
  type: [UNPROCESS_DOCUMENT_REQUEST, UNPROCESS_DOCUMENT_SUCCESS, UNPROCESS_DOCUMENT_FAILURE],
  promise: (api: IApi) => api.dueDiligence.unprocessDocument(id, type, flowId),
  params: {
    id,
    type,
  },
})

export const LIST_ALIAS_MAPPING_REQUEST = `${prefix}/LIST_ALIAS_MAPPING_REQUEST`
export const LIST_ALIAS_MAPPING_SUCCESS = `${prefix}/LIST_ALIAS_MAPPING_SUCCESS`
export const LIST_ALIAS_MAPPING_FAILURE = `${prefix}/LIST_ALIAS_MAPPING_FAILURE`

export const listAliasMapping = (id: string, params: object) => ({
  type: [LIST_ALIAS_MAPPING_REQUEST, LIST_ALIAS_MAPPING_SUCCESS, LIST_ALIAS_MAPPING_FAILURE],
  promise: (api: IApi) => api.dueDiligence.listAliasMapping(id, params),
  params,
})

export const HIDE_ALIAS_MAPPING = `${prefix}/HIDE_ALIAS_MAPPING`

export const hideAliasMapping = () => ({
  type: HIDE_ALIAS_MAPPING,
})

export const CLIENT_INFO_REQUEST = `${prefix}/CLIENT_INFO_REQUEST`
export const CLIENT_INFO_SUCCESS = `${prefix}/CLIENT_INFO_SUCCESS`
export const CLIENT_INFO_FAILURE = `${prefix}/CLIENT_INFO_FAILURE`

export const loadClientInfo: CommonPromiseAction = () => ({
  type: [CLIENT_INFO_REQUEST, CLIENT_INFO_SUCCESS, CLIENT_INFO_FAILURE],
  promise: (api: IApi) => api.dueDiligence.clientInfo(),
})

export const CLIENT_INFO_RELOAD_REQUEST = `${prefix}/CLIENT_INFO_RELOAD_REQUEST`
export const CLIENT_INFO_RELOAD_SUCCESS = `${prefix}/CLIENT_INFO_RELOAD_SUCCESS`
export const CLIENT_INFO_RELOAD_FAILURE = `${prefix}/CLIENT_INFO_RELOAD_FAILURE`

export const reloadClientInfo: CommonPromiseAction = () => ({
  type: [CLIENT_INFO_RELOAD_REQUEST, CLIENT_INFO_RELOAD_SUCCESS, CLIENT_INFO_RELOAD_FAILURE],
  promise: (api: IApi) => api.dueDiligence.clientInfo(),
})

export const SHOW_DOCUMENT_REQUESTS_TYPES_REQUEST = `${prefix}/SHOW_DOCUMENT_VALIDATE_TYPES_LIST_REQUEST`
export const SHOW_DOCUMENT_REQUESTS_TYPES_SUCCESS = `${prefix}/SHOW_DOCUMENT_VALIDATE_TYPES_LIST_SUCCESS`
export const SHOW_DOCUMENT_REQUESTS_TYPES_FAILURE = `${prefix}/SHOW_DOCUMENT_VALIDATE_TYPES_LIST_FAILURE`

export const showDocumentRequestsTypes: CommonPromiseAction = (id: string) => ({
  type: [
    SHOW_DOCUMENT_REQUESTS_TYPES_REQUEST,
    SHOW_DOCUMENT_REQUESTS_TYPES_SUCCESS,
    SHOW_DOCUMENT_REQUESTS_TYPES_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.showDocumentRequestsTypes(id),
})

export const HIDE_DOCUMENT_REQUESTS_TYPES = `${prefix}/HIDE_DOCUMENT_REQUESTS_TYPES`
export const hideDocumentRequestsTypes: CommonPromiseAction = () => ({
  type: HIDE_DOCUMENT_REQUESTS_TYPES,
})

export const SHOW_DOCUMENT_REQUESTS_REQUEST = `${prefix}/SHOW_DOCUMENT_VALIDATE_LIST_REQUEST`
export const SHOW_DOCUMENT_REQUESTS_SUCCESS = `${prefix}/SHOW_DOCUMENT_VALIDATE_LIST_SUCCESS`
export const SHOW_DOCUMENT_REQUESTS_FAILURE = `${prefix}/SHOW_DOCUMENT_VALIDATE_LIST_FAILURE`

export const showDocumentRequests: CommonPromiseAction = (id?: string, params: object = {}) => ({
  type: [
    SHOW_DOCUMENT_REQUESTS_REQUEST,
    SHOW_DOCUMENT_REQUESTS_SUCCESS,
    SHOW_DOCUMENT_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.showDocumentRequests(id, params),
  params,
})

export const HIDE_DOCUMENT_REQUESTS = `${prefix}/HIDE_DOCUMENT_REQUESTS`
export const hideDocumentRequests: CommonPromiseAction = () => ({
  type: HIDE_DOCUMENT_REQUESTS,
})

export const UPLOAD_DOCUMENT_REQUESTS_REQUEST = `${prefix}/UPLOAD_DOCUMENT_VALIDATE_LIST_REQUEST`
export const UPLOAD_DOCUMENT_REQUESTS_SUCCESS = `${prefix}/UPLOAD_DOCUMENT_VALIDATE_LIST_SUCCESS`
export const UPLOAD_DOCUMENT_REQUESTS_FAILURE = `${prefix}/UPLOAD_DOCUMENT_VALIDATE_LIST_FAILURE`

export const uploadDocumentRequestsFiles: CommonPromiseAction = (
  data: FormData,
  clientId?: string,
) => ({
  type: [
    UPLOAD_DOCUMENT_REQUESTS_REQUEST,
    UPLOAD_DOCUMENT_REQUESTS_SUCCESS,
    UPLOAD_DOCUMENT_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.uploadDocumentRequestsFiles(data, clientId),
})

export const GET_DOCUMENT_REQUESTS_SHARED_LINK_REQUEST = `${prefix}/GET_DOCUMENT_VALIDATE_LIST_SHARED_LINK_REQUEST`
export const GET_DOCUMENT_REQUESTS_SHARED_LINK_SUCCESS = `${prefix}/GET_DOCUMENT_VALIDATE_LIST_SHARED_LINK_SUCCESS`
export const GET_DOCUMENT_REQUESTS_SHARED_LINK_FAILURE = `${prefix}/GET_DOCUMENT_VALIDATE_LIST_SHARED_LINK_FAILURE`

export const getDocumentRequestsSharedLink: CommonPromiseAction = (
  documentRequestId: string,
  clientId?: string,
) => ({
  type: [
    GET_DOCUMENT_REQUESTS_SHARED_LINK_REQUEST,
    GET_DOCUMENT_REQUESTS_SHARED_LINK_SUCCESS,
    GET_DOCUMENT_REQUESTS_SHARED_LINK_FAILURE,
  ],
  promise: (api: IApi) =>
    api.dueDiligence.getDocumentRequestsSharedLink(documentRequestId, clientId),
})

export const CREATE_DOCUMENT_REQUESTS_REQUEST = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_REQUEST`
export const CREATE_DOCUMENT_REQUESTS_SUCCESS = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_SUCCESS`
export const CREATE_DOCUMENT_REQUESTS_FAILURE = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_FAILURE`

export const createDocumentRequest: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    CREATE_DOCUMENT_REQUESTS_REQUEST,
    CREATE_DOCUMENT_REQUESTS_SUCCESS,
    CREATE_DOCUMENT_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.createDocumentRequest(id, data),
})

export const UPDATE_DOCUMENT_REQUESTS_REQUEST = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_REQUEST`
export const UPDATE_DOCUMENT_REQUESTS_SUCCESS = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_SUCCESS`
export const UPDATE_DOCUMENT_REQUESTS_FAILURE = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_FAILURE`

export const updateDocumentRequest: CommonPromiseAction = (
  id: string,
  documentRequestId: string,
  data: object,
) => ({
  type: [
    UPDATE_DOCUMENT_REQUESTS_REQUEST,
    UPDATE_DOCUMENT_REQUESTS_SUCCESS,
    UPDATE_DOCUMENT_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateDocumentRequest(id, documentRequestId, data),
})

export const UPDATE_DOCUMENTS_REQUESTS_REQUEST = `${prefix}/UPDATE_DOCUMENTS_VALIDATE_LIST_REQUEST`
export const UPDATE_DOCUMENTS_REQUESTS_SUCCESS = `${prefix}/UPDATE_DOCUMENTS_VALIDATE_LIST_SUCCESS`
export const UPDATE_DOCUMENTS_REQUESTS_FAILURE = `${prefix}/UPDATE_DOCUMENTS_VALIDATE_LIST_FAILURE`

export const updateDocumentsRequests: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_DOCUMENTS_REQUESTS_REQUEST,
    UPDATE_DOCUMENTS_REQUESTS_SUCCESS,
    UPDATE_DOCUMENTS_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateDocumentsRequests(id, data),
})

export const DELETE_DOCUMENT_REQUESTS_REQUEST = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_REQUEST`
export const DELETE_DOCUMENT_REQUESTS_SUCCESS = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_SUCCESS`
export const DELETE_DOCUMENT_REQUESTS_FAILURE = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_FAILURE`

export const deleteDocumentRequest: CommonPromiseAction = (
  id: string,
  documentRequestId: string,
) => ({
  type: [
    DELETE_DOCUMENT_REQUESTS_REQUEST,
    DELETE_DOCUMENT_REQUESTS_SUCCESS,
    DELETE_DOCUMENT_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteDocumentRequest(id, documentRequestId),
})

export const DELETE_DOCUMENTS_REQUESTS_REQUEST = `${prefix}/DELETE_DOCUMENTS_VALIDATE_LIST_REQUEST`
export const DELETE_DOCUMENTS_REQUESTS_SUCCESS = `${prefix}/DELETE_DOCUMENTS_VALIDATE_LIST_SUCCESS`
export const DELETE_DOCUMENTS_REQUESTS_FAILURE = `${prefix}/DELETE_DOCUMENTS_VALIDATE_LIST_FAILURE`

export const deleteDocumentsRequests: CommonPromiseAction = (
  id: string,
  documentRequestIds: string[],
) => ({
  type: [
    DELETE_DOCUMENTS_REQUESTS_REQUEST,
    DELETE_DOCUMENTS_REQUESTS_SUCCESS,
    DELETE_DOCUMENTS_REQUESTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteDocumentsRequests(id, documentRequestIds),
})

const DELETE_DOCUMENTS = createRequestTypes(prefix, 'DELETE_DOCUMENTS')
export const [DELETE_DOCUMENTS_REQUEST, DELETE_DOCUMENTS_SUCCESS, DELETE_DOCUMENTS_FAILURE] =
  DELETE_DOCUMENTS

export const deleteDocument: CommonPromiseAction = (id: string) => ({
  type: [DELETE_DOCUMENTS_REQUEST, DELETE_DOCUMENTS_SUCCESS, DELETE_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.deleteDocument(id),
  params: {
    skipLoader: true,
  },
})

const CREATE_DOCUMENTS = createRequestTypes(prefix, 'CREATE_DOCUMENTS')
export const [CREATE_DOCUMENTS_REQUEST, CREATE_DOCUMENTS_SUCCESS, CREATE_DOCUMENTS_FAILURE] =
  CREATE_DOCUMENTS

export const createDocument: CommonPromiseAction = (id: string) => ({
  type: [CREATE_DOCUMENTS_REQUEST, CREATE_DOCUMENTS_SUCCESS, CREATE_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.createDocument(id),
  params: {
    skipLoader: true,
  },
})

export const CREATE_DOCUMENT_REQUESTS_COMMENT_REQUEST = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_COMMENT_REQUEST`
export const CREATE_DOCUMENT_REQUESTS_COMMENT_SUCCESS = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_COMMENT_SUCCESS`
export const CREATE_DOCUMENT_REQUESTS_COMMENT_FAILURE = `${prefix}/CREATE_DOCUMENT_VALIDATE_LIST_COMMENT_FAILURE`

export const createDocumentRequestComment: CommonPromiseAction = (
  documentRequestId: string,
  data: object,
  id?: string,
) => ({
  type: [
    CREATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
    CREATE_DOCUMENT_REQUESTS_COMMENT_SUCCESS,
    CREATE_DOCUMENT_REQUESTS_COMMENT_FAILURE,
  ],
  promise: (api: IApi) =>
    api.dueDiligence.createDocumentRequestComment(documentRequestId, data, id),
  params: {
    customLiveEdit: (state: any, action: any) => ({
      ...state,
      data: {
        ...state.data,
        data: [
          ...(state.data.data || []).map((item: any) =>
            item.id === action.data.ddDocumentRequestId
              ? {
                  ...item,
                  comments: item.comments.find((comment: any) => comment.id === action.data.id)
                    ? item.comments.map((comment: any) =>
                        comment.id === action.data.id ? action.data : comment,
                      )
                    : [...item.comments, action.data],
                }
              : item,
          ),
        ],
      },
    }),
  },
})

export const UPDATE_DOCUMENT_REQUESTS_COMMENT_REQUEST = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_COMMENT_REQUEST`
export const UPDATE_DOCUMENT_REQUESTS_COMMENT_SUCCESS = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_COMMENT_SUCCESS`
export const UPDATE_DOCUMENT_REQUESTS_COMMENT_FAILURE = `${prefix}/UPDATE_DOCUMENT_VALIDATE_LIST_COMMENT_FAILURE`

export const updateDocumentRequestComment: CommonPromiseAction = (
  documentRequestId: string,
  commentId: string,
  data: object,
  id?: string,
) => ({
  type: [
    UPDATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
    UPDATE_DOCUMENT_REQUESTS_COMMENT_SUCCESS,
    UPDATE_DOCUMENT_REQUESTS_COMMENT_FAILURE,
  ],
  promise: (api: IApi) =>
    api.dueDiligence.updateDocumentRequestComment(documentRequestId, commentId, data, id),
  params: {
    customLiveEdit: (state: any, action: any) => ({
      ...state,
      data: {
        ...state.data,
        data: [
          ...(state.data.data || []).map((item: any) =>
            item.id === action.data.ddDocumentRequestId
              ? {
                  ...item,
                  comments: item.comments.map((comment: any) =>
                    comment.id === action.data.id ? action.data : comment,
                  ),
                }
              : item,
          ),
        ],
      },
    }),
  },
})

export const DELETE_DOCUMENT_REQUESTS_COMMENT_REQUEST = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_COMMENT_REQUEST`
export const DELETE_DOCUMENT_REQUESTS_COMMENT_SUCCESS = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_COMMENT_SUCCESS`
export const DELETE_DOCUMENT_REQUESTS_COMMENT_FAILURE = `${prefix}/DELETE_DOCUMENT_VALIDATE_LIST_COMMENT_FAILURE`

export const deleteDocumentRequestComment: CommonPromiseAction = (
  documentRequestId: string,
  commentId: string,
  id?: string,
) => ({
  type: [
    DELETE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
    DELETE_DOCUMENT_REQUESTS_COMMENT_SUCCESS,
    DELETE_DOCUMENT_REQUESTS_COMMENT_FAILURE,
  ],
  promise: (api: IApi) =>
    api.dueDiligence.deleteDocumentRequestComment(documentRequestId, commentId, id),
  params: {
    customLiveEdit: (state: any, action: any) => ({
      ...state,
      data: {
        ...state.data,
        data: [
          ...(state.data.data || []).map((item: any) =>
            item.id === action.data.ddDocumentRequestId
              ? {
                  ...item,
                  comments: item.comments.filter((comment: any) => comment.id !== action.data.id),
                }
              : item,
          ),
        ],
      },
    }),
  },
})

export const COMPANY_INFO_REQUEST = `${prefix}/COMPANY_INFO_REQUEST`
export const COMPANY_INFO_SUCCESS = `${prefix}/COMPANY_INFO_SUCCESS`
export const COMPANY_INFO_FAILURE = `${prefix}/COMPANY_INFO_FAILURE`

export const getCompanyInfo: CommonPromiseAction = () => ({
  type: [COMPANY_INFO_REQUEST, COMPANY_INFO_SUCCESS, COMPANY_INFO_FAILURE],
  promise: (api: IApi) => api.dueDiligence.getCompanyInfo(),
})

export const UPDATE_COMPANY_INFO_REQUEST = `${prefix}/UPDATE_COMPANY_INFO_REQUEST`
export const UPDATE_COMPANY_INFO_SUCCESS = `${prefix}/UPDATE_COMPANY_INFO_SUCCESS`
export const UPDATE_COMPANY_INFO_FAILURE = `${prefix}/UPDATE_COMPANY_INFO_FAILURE`

export const updateCompanyInfo: CommonPromiseAction = (data: object) => ({
  type: [UPDATE_COMPANY_INFO_REQUEST, UPDATE_COMPANY_INFO_SUCCESS, UPDATE_COMPANY_INFO_FAILURE],
  promise: (api: IApi) => api.dueDiligence.updateCompanyInfo(data),
})

export const LIST_TEAM_CONTACTS_REQUEST = `${prefix}/LIST_TEAM_CONTACTS_REQUEST`
export const LIST_TEAM_CONTACTS_SUCCESS = `${prefix}/LIST_TEAM_CONTACTS_SUCCESS`
export const LIST_TEAM_CONTACTS_FAILURE = `${prefix}/LIST_TEAM_CONTACTS_FAILURE`

export const listTeamContacts: CommonPromiseAction = (params: object = {}, clientId?: string) => ({
  type: [LIST_TEAM_CONTACTS_REQUEST, LIST_TEAM_CONTACTS_SUCCESS, LIST_TEAM_CONTACTS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.listTeamContacts(params, clientId),
  params,
})

export const HIDE_TEAM_CONTACTS = `${prefix}/HIDE_TEAM_CONTACTS`

export const hideTeamContacts: CommonPromiseAction = () => ({
  type: HIDE_TEAM_CONTACTS,
})

export const ADD_TEAM_CONTACT_REQUEST = `${prefix}/ADD_TEAM_CONTACT_REQUEST`
export const ADD_TEAM_CONTACT_SUCCESS = `${prefix}/ADD_TEAM_CONTACT_SUCCESS`
export const ADD_TEAM_CONTACT_FAILURE = `${prefix}/ADD_TEAM_CONTACT_FAILURE`

export const addTeamContact: CommonPromiseAction = (data: object) => ({
  type: [ADD_TEAM_CONTACT_REQUEST, ADD_TEAM_CONTACT_SUCCESS, ADD_TEAM_CONTACT_FAILURE],
  promise: (api: IApi) => api.dueDiligence.addTeamContact(data),
})

export const UPDATE_TEAM_CONTACT_REQUEST = `${prefix}/UPDATE_TEAM_CONTACT_REQUEST`
export const UPDATE_TEAM_CONTACT_SUCCESS = `${prefix}/UPDATE_TEAM_CONTACT_SUCCESS`
export const UPDATE_TEAM_CONTACT_FAILURE = `${prefix}/UPDATE_TEAM_CONTACT_FAILURE`

export const updateTeamContact: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_TEAM_CONTACT_REQUEST, UPDATE_TEAM_CONTACT_SUCCESS, UPDATE_TEAM_CONTACT_FAILURE],
  promise: (api: IApi) => api.dueDiligence.updateTeamContact(id, data),
})

export const DELETE_TEAM_CONTACT_REQUEST = `${prefix}/DELETE_TEAM_CONTACT_REQUEST`
export const DELETE_TEAM_CONTACT_SUCCESS = `${prefix}/DELETE_TEAM_CONTACT_SUCCESS`
export const DELETE_TEAM_CONTACT_FAILURE = `${prefix}/DELETE_TEAM_CONTACT_FAILURE`

export const deleteTeamContact: CommonPromiseAction = (id: string) => ({
  type: [DELETE_TEAM_CONTACT_REQUEST, DELETE_TEAM_CONTACT_SUCCESS, DELETE_TEAM_CONTACT_FAILURE],
  promise: (api: IApi) => api.dueDiligence.deleteTeamContact(id),
})

export const LIST_TEAM_EXECUTIVES_REQUEST = `${prefix}/LIST_TEAM_EXECUTIVES_REQUEST`
export const LIST_TEAM_EXECUTIVES_SUCCESS = `${prefix}/LIST_TEAM_EXECUTIVES_SUCCESS`
export const LIST_TEAM_EXECUTIVES_FAILURE = `${prefix}/LIST_TEAM_EXECUTIVES_FAILURE`

export const listTeamExecutives: CommonPromiseAction = (
  params: object = {},
  clientId?: string,
) => ({
  type: [LIST_TEAM_EXECUTIVES_REQUEST, LIST_TEAM_EXECUTIVES_SUCCESS, LIST_TEAM_EXECUTIVES_FAILURE],
  promise: (api: IApi) => api.dueDiligence.listTeamExecutives(params, clientId),
  params,
})

export const HIDE_TEAM_EXECUTIVES = `${prefix}/HIDE_TEAM_EXECUTIVES`

export const hideTeamExecutives: CommonPromiseAction = () => ({
  type: HIDE_TEAM_EXECUTIVES,
})

export const ADD_TEAM_EXECUTIVE_REQUEST = `${prefix}/ADD_TEAM_EXECUTIVE_REQUEST`
export const ADD_TEAM_EXECUTIVE_SUCCESS = `${prefix}/ADD_TEAM_EXECUTIVE_SUCCESS`
export const ADD_TEAM_EXECUTIVE_FAILURE = `${prefix}/ADD_TEAM_EXECUTIVE_FAILURE`

export const addTeamExecutive: CommonPromiseAction = (data: object) => ({
  type: [ADD_TEAM_EXECUTIVE_REQUEST, ADD_TEAM_EXECUTIVE_SUCCESS, ADD_TEAM_EXECUTIVE_FAILURE],
  promise: (api: IApi) => api.dueDiligence.addTeamExecutive(data),
})

export const UPDATE_TEAM_EXECUTIVE_REQUEST = `${prefix}/UPDATE_TEAM_EXECUTIVE_REQUEST`
export const UPDATE_TEAM_EXECUTIVE_SUCCESS = `${prefix}/UPDATE_TEAM_EXECUTIVE_SUCCESS`
export const UPDATE_TEAM_EXECUTIVE_FAILURE = `${prefix}/UPDATE_TEAM_EXECUTIVE_FAILURE`

export const updateTeamExecutive: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_TEAM_EXECUTIVE_REQUEST,
    UPDATE_TEAM_EXECUTIVE_SUCCESS,
    UPDATE_TEAM_EXECUTIVE_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateTeamExecutive(id, data),
})

export const DELETE_TEAM_EXECUTIVE_REQUEST = `${prefix}/DELETE_TEAM_EXECUTIVE_REQUEST`
export const DELETE_TEAM_EXECUTIVE_SUCCESS = `${prefix}/DELETE_TEAM_EXECUTIVE_SUCCESS`
export const DELETE_TEAM_EXECUTIVE_FAILURE = `${prefix}/DELETE_TEAM_EXECUTIVE_FAILURE`

export const deleteTeamExecutive: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_TEAM_EXECUTIVE_REQUEST,
    DELETE_TEAM_EXECUTIVE_SUCCESS,
    DELETE_TEAM_EXECUTIVE_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteTeamExecutive(id),
})

export const LIST_TEAM_BOARD_MEMBERS_REQUEST = `${prefix}/LIST_TEAM_BOARD_MEMBERS_REQUEST`
export const LIST_TEAM_BOARD_MEMBERS_SUCCESS = `${prefix}/LIST_TEAM_BOARD_MEMBERS_SUCCESS`
export const LIST_TEAM_BOARD_MEMBERS_FAILURE = `${prefix}/LIST_TEAM_BOARD_MEMBERS_FAILURE`

export const listTeamBoardMembers: CommonPromiseAction = (
  params: object = {},
  clientId?: string,
) => ({
  type: [
    LIST_TEAM_BOARD_MEMBERS_REQUEST,
    LIST_TEAM_BOARD_MEMBERS_SUCCESS,
    LIST_TEAM_BOARD_MEMBERS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.listTeamBoardMembers(params, clientId),
  params,
})

export const HIDE_TEAM_BOARD_MEMBERS = `${prefix}/HIDE_TEAM_BOARD_MEMBERS`

export const hideTeamBoardMembers: CommonPromiseAction = () => ({
  type: HIDE_TEAM_BOARD_MEMBERS,
})

export const ADD_TEAM_BOARD_MEMBER_REQUEST = `${prefix}/ADD_TEAM_BOARD_MEMBER_REQUEST`
export const ADD_TEAM_BOARD_MEMBER_SUCCESS = `${prefix}/ADD_TEAM_BOARD_MEMBER_SUCCESS`
export const ADD_TEAM_BOARD_MEMBER_FAILURE = `${prefix}/ADD_TEAM_BOARD_MEMBER_FAILURE`

export const addTeamBoardMember: CommonPromiseAction = (data: object) => ({
  type: [
    ADD_TEAM_BOARD_MEMBER_REQUEST,
    ADD_TEAM_BOARD_MEMBER_SUCCESS,
    ADD_TEAM_BOARD_MEMBER_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.addTeamBoardMember(data),
})

export const UPDATE_TEAM_BOARD_MEMBER_REQUEST = `${prefix}/UPDATE_TEAM_BOARD_MEMBER_REQUEST`
export const UPDATE_TEAM_BOARD_MEMBER_SUCCESS = `${prefix}/UPDATE_TEAM_BOARD_MEMBER_SUCCESS`
export const UPDATE_TEAM_BOARD_MEMBER_FAILURE = `${prefix}/UPDATE_TEAM_BOARD_MEMBER_FAILURE`

export const updateTeamBoardMember: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_TEAM_BOARD_MEMBER_REQUEST,
    UPDATE_TEAM_BOARD_MEMBER_SUCCESS,
    UPDATE_TEAM_BOARD_MEMBER_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateTeamBoardMember(id, data),
})

export const DELETE_TEAM_BOARD_MEMBER_REQUEST = `${prefix}/DELETE_TEAM_BOARD_MEMBER_REQUEST`
export const DELETE_TEAM_BOARD_MEMBER_SUCCESS = `${prefix}/DELETE_TEAM_BOARD_MEMBER_SUCCESS`
export const DELETE_TEAM_BOARD_MEMBER_FAILURE = `${prefix}/DELETE_TEAM_BOARD_MEMBER_FAILURE`

export const deleteTeamBoardMember: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_TEAM_BOARD_MEMBER_REQUEST,
    DELETE_TEAM_BOARD_MEMBER_SUCCESS,
    DELETE_TEAM_BOARD_MEMBER_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteTeamBoardMember(id),
})

export const LIST_TEAM_REFERENCES_REQUEST = `${prefix}/LIST_TEAM_REFERENCES_REQUEST`
export const LIST_TEAM_REFERENCES_SUCCESS = `${prefix}/LIST_TEAM_REFERENCES_SUCCESS`
export const LIST_TEAM_REFERENCES_FAILURE = `${prefix}/LIST_TEAM_REFERENCES_FAILURE`

export const listTeamReferences: CommonPromiseAction = (
  params: object = {},
  clientId?: string,
) => ({
  type: [LIST_TEAM_REFERENCES_REQUEST, LIST_TEAM_REFERENCES_SUCCESS, LIST_TEAM_REFERENCES_FAILURE],
  promise: (api: IApi) => api.dueDiligence.listTeamReferences(params, clientId),
  params,
})

export const HIDE_TEAM_REFERENCES = `${prefix}/HIDE_TEAM_REFERENCES`

export const hideTeamReferences: CommonPromiseAction = () => ({
  type: HIDE_TEAM_REFERENCES,
})

export const ADD_TEAM_REFERENCE_REQUEST = `${prefix}/ADD_TEAM_REFERENCE_REQUEST`
export const ADD_TEAM_REFERENCE_SUCCESS = `${prefix}/ADD_TEAM_REFERENCE_SUCCESS`
export const ADD_TEAM_REFERENCE_FAILURE = `${prefix}/ADD_TEAM_REFERENCE_FAILURE`

export const addTeamReference: CommonPromiseAction = (data: object) => ({
  type: [ADD_TEAM_REFERENCE_REQUEST, ADD_TEAM_REFERENCE_SUCCESS, ADD_TEAM_REFERENCE_FAILURE],
  promise: (api: IApi) => api.dueDiligence.addTeamReference(data),
})

export const UPDATE_TEAM_REFERENCE_REQUEST = `${prefix}/UPDATE_TEAM_REFERENCE_REQUEST`
export const UPDATE_TEAM_REFERENCE_SUCCESS = `${prefix}/UPDATE_TEAM_REFERENCE_SUCCESS`
export const UPDATE_TEAM_REFERENCE_FAILURE = `${prefix}/UPDATE_TEAM_REFERENCE_FAILURE`

export const updateTeamReference: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_TEAM_REFERENCE_REQUEST,
    UPDATE_TEAM_REFERENCE_SUCCESS,
    UPDATE_TEAM_REFERENCE_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateTeamReference(id, data),
})

export const DELETE_TEAM_REFERENCE_REQUEST = `${prefix}/DELETE_TEAM_REFERENCE_REQUEST`
export const DELETE_TEAM_REFERENCE_SUCCESS = `${prefix}/DELETE_TEAM_REFERENCE_SUCCESS`
export const DELETE_TEAM_REFERENCE_FAILURE = `${prefix}/DELETE_TEAM_REFERENCE_FAILURE`

export const deleteTeamReference: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_TEAM_REFERENCE_REQUEST,
    DELETE_TEAM_REFERENCE_SUCCESS,
    DELETE_TEAM_REFERENCE_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteTeamReference(id),
})

export const LOAD_VENDORS_REQUEST = `${prefix}/LOAD_VENDORS_REQUEST`
export const LOAD_VENDORS_SUCCESS = `${prefix}/LOAD_VENDORS_SUCCESS`
export const LOAD_VENDORS_FAILURE = `${prefix}/LOAD_VENDORS_FAILURE`

export const loadVendors: CommonPromiseAction = (data: object) => ({
  type: [LOAD_VENDORS_REQUEST, LOAD_VENDORS_SUCCESS, LOAD_VENDORS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.loadVendors(data),
})

export const UPDATE_TEAM_ADVISORS_REQUEST = `${prefix}/UPDATE_TEAM_ADVISORS_REQUEST`
export const UPDATE_TEAM_ADVISORS_SUCCESS = `${prefix}/UPDATE_TEAM_ADVISORS_SUCCESS`
export const UPDATE_TEAM_ADVISORS_FAILURE = `${prefix}/UPDATE_TEAM_ADVISORS_FAILURE`

export const updateTeamAdvisorsInfo: CommonPromiseAction = (data: object) => ({
  type: [UPDATE_TEAM_ADVISORS_REQUEST, UPDATE_TEAM_ADVISORS_SUCCESS, UPDATE_TEAM_ADVISORS_FAILURE],
  promise: (api: IApi) => api.dueDiligence.updateTeamAdvisorsInfo(data),
})

export const UPDATE_FINANCIALS_VENDORS_REQUEST = `${prefix}/UPDATE_FINANCIALS_VENDORS_REQUEST`
export const UPDATE_FINANCIALS_VENDORS_SUCCESS = `${prefix}/UPDATE_FINANCIALS_VENDORS_SUCCESS`
export const UPDATE_FINANCIALS_VENDORS_FAILURE = `${prefix}/UPDATE_FINANCIALS_VENDORS_FAILURE`

export const updateFinancialsVendorsInfo: CommonPromiseAction = (data: object) => ({
  type: [
    UPDATE_FINANCIALS_VENDORS_REQUEST,
    UPDATE_FINANCIALS_VENDORS_SUCCESS,
    UPDATE_FINANCIALS_VENDORS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateFinancialsVendorsInfo(data),
})

export const GET_FINANCIALS_CODAT_LINK_REQUEST = `${prefix}/GET_FINANCIALS_CODAT_LINK_REQUEST`
export const GET_FINANCIALS_CODAT_LINK_SUCCESS = `${prefix}/GET_FINANCIALS_CODAT_LINK_SUCCESS`
export const GET_FINANCIALS_CODAT_LINK_FAILURE = `${prefix}/GET_FINANCIALS_CODAT_LINK_FAILURE`

export const getFinancialsCodatLink: CommonPromiseAction = () => ({
  type: [
    GET_FINANCIALS_CODAT_LINK_REQUEST,
    GET_FINANCIALS_CODAT_LINK_SUCCESS,
    GET_FINANCIALS_CODAT_LINK_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.getFinancialsCodatLink(),
})

export const LIST_FINANCIALS_INVENTORY_LOCATIONS_REQUEST = `${prefix}/LIST_FINANCIALS_INVENTORY_LOCATIONS_REQUEST`
export const LIST_FINANCIALS_INVENTORY_LOCATIONS_SUCCESS = `${prefix}/LIST_FINANCIALS_INVENTORY_LOCATIONS_SUCCESS`
export const LIST_FINANCIALS_INVENTORY_LOCATIONS_FAILURE = `${prefix}/LIST_FINANCIALS_INVENTORY_LOCATIONS_FAILURE`

export const listFinancialsInventoryLocations: CommonPromiseAction = (
  params: object = {},
  clientId?: string,
) => ({
  type: [
    LIST_FINANCIALS_INVENTORY_LOCATIONS_REQUEST,
    LIST_FINANCIALS_INVENTORY_LOCATIONS_SUCCESS,
    LIST_FINANCIALS_INVENTORY_LOCATIONS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.listFinancialsInventoryLocations(params, clientId),
  params,
})

export const HIDE_FINANCIALS_INVENTORY_LOCATIONS = `${prefix}/HIDE_FINANCIALS_INVENTORY_LOCATIONS`

export const hideFinancialsInventoryLocations: CommonPromiseAction = () => ({
  type: HIDE_FINANCIALS_INVENTORY_LOCATIONS,
})

export const ADD_FINANCIALS_INVENTORY_LOCATION_REQUEST = `${prefix}/ADD_FINANCIALS_INVENTORY_LOCATION_REQUEST`
export const ADD_FINANCIALS_INVENTORY_LOCATION_SUCCESS = `${prefix}/ADD_FINANCIALS_INVENTORY_LOCATION_SUCCESS`
export const ADD_FINANCIALS_INVENTORY_LOCATION_FAILURE = `${prefix}/ADD_FINANCIALS_INVENTORY_LOCATION_FAILURE`

export const addFinancialsInventoryLocation: CommonPromiseAction = (data: object) => ({
  type: [
    ADD_FINANCIALS_INVENTORY_LOCATION_REQUEST,
    ADD_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
    ADD_FINANCIALS_INVENTORY_LOCATION_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.addFinancialsInventoryLocation(data),
})

export const UPDATE_FINANCIALS_INVENTORY_LOCATION_REQUEST = `${prefix}/UPDATE_FINANCIALS_INVENTORY_LOCATION_REQUEST`
export const UPDATE_FINANCIALS_INVENTORY_LOCATION_SUCCESS = `${prefix}/UPDATE_FINANCIALS_INVENTORY_LOCATION_SUCCESS`
export const UPDATE_FINANCIALS_INVENTORY_LOCATION_FAILURE = `${prefix}/UPDATE_FINANCIALS_INVENTORY_LOCATION_FAILURE`

export const updateFinancialsInventoryLocation: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    UPDATE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
    UPDATE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
    UPDATE_FINANCIALS_INVENTORY_LOCATION_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateFinancialsInventoryLocation(id, data),
})

export const DELETE_FINANCIALS_INVENTORY_LOCATION_REQUEST = `${prefix}/DELETE_FINANCIALS_INVENTORY_LOCATION_REQUEST`
export const DELETE_FINANCIALS_INVENTORY_LOCATION_SUCCESS = `${prefix}/DELETE_FINANCIALS_INVENTORY_LOCATION_SUCCESS`
export const DELETE_FINANCIALS_INVENTORY_LOCATION_FAILURE = `${prefix}/DELETE_FINANCIALS_INVENTORY_LOCATION_FAILURE`

export const deleteFinancialsInventoryLocation: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
    DELETE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
    DELETE_FINANCIALS_INVENTORY_LOCATION_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteFinancialsInventoryLocation(id),
})

export const LIST_FINANCIALS_BANK_ACCOUNTS_REQUEST = `${prefix}/LIST_FINANCIALS_BANK_ACCOUNTS_REQUEST`
export const LIST_FINANCIALS_BANK_ACCOUNTS_SUCCESS = `${prefix}/LIST_FINANCIALS_BANK_ACCOUNTS_SUCCESS`
export const LIST_FINANCIALS_BANK_ACCOUNTS_FAILURE = `${prefix}/LIST_FINANCIALS_BANK_ACCOUNTS_FAILURE`

export const listFinancialsBankAccounts: CommonPromiseAction = (
  params: object = {},
  clientId?: string,
) => ({
  type: [
    LIST_FINANCIALS_BANK_ACCOUNTS_REQUEST,
    LIST_FINANCIALS_BANK_ACCOUNTS_SUCCESS,
    LIST_FINANCIALS_BANK_ACCOUNTS_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.listFinancialsBankAccounts(params, clientId),
  params,
})

export const HIDE_FINANCIALS_BANK_ACCOUNTS = `${prefix}/HIDE_FINANCIALS_BANK_ACCOUNTS`

export const hideFinancialsBankAccounts: CommonPromiseAction = () => ({
  type: HIDE_FINANCIALS_BANK_ACCOUNTS,
})

export const ADD_FINANCIALS_BANK_ACCOUNT_REQUEST = `${prefix}/ADD_FINANCIALS_BANK_ACCOUNT_REQUEST`
export const ADD_FINANCIALS_BANK_ACCOUNT_SUCCESS = `${prefix}/ADD_FINANCIALS_BANK_ACCOUNT_SUCCESS`
export const ADD_FINANCIALS_BANK_ACCOUNT_FAILURE = `${prefix}/ADD_FINANCIALS_BANK_ACCOUNT_FAILURE`

export const addFinancialsBankAccount: CommonPromiseAction = (data: object) => ({
  type: [
    ADD_FINANCIALS_BANK_ACCOUNT_REQUEST,
    ADD_FINANCIALS_BANK_ACCOUNT_SUCCESS,
    ADD_FINANCIALS_BANK_ACCOUNT_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.addFinancialsBankAccount(data),
})

export const UPDATE_FINANCIALS_BANK_ACCOUNT_REQUEST = `${prefix}/UPDATE_FINANCIALS_BANK_ACCOUNT_REQUEST`
export const UPDATE_FINANCIALS_BANK_ACCOUNT_SUCCESS = `${prefix}/UPDATE_FINANCIALS_BANK_ACCOUNT_SUCCESS`
export const UPDATE_FINANCIALS_BANK_ACCOUNT_FAILURE = `${prefix}/UPDATE_FINANCIALS_BANK_ACCOUNT_FAILURE`

export const updateFinancialsBankAccount: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_FINANCIALS_BANK_ACCOUNT_REQUEST,
    UPDATE_FINANCIALS_BANK_ACCOUNT_SUCCESS,
    UPDATE_FINANCIALS_BANK_ACCOUNT_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.updateFinancialsBankAccount(id, data),
})

export const DELETE_FINANCIALS_BANK_ACCOUNT_REQUEST = `${prefix}/DELETE_FINANCIALS_BANK_ACCOUNT_REQUEST`
export const DELETE_FINANCIALS_BANK_ACCOUNT_SUCCESS = `${prefix}/DELETE_FINANCIALS_BANK_ACCOUNT_SUCCESS`
export const DELETE_FINANCIALS_BANK_ACCOUNT_FAILURE = `${prefix}/DELETE_FINANCIALS_BANK_ACCOUNT_FAILURE`

export const deleteFinancialsBankAccount: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_FINANCIALS_BANK_ACCOUNT_REQUEST,
    DELETE_FINANCIALS_BANK_ACCOUNT_SUCCESS,
    DELETE_FINANCIALS_BANK_ACCOUNT_FAILURE,
  ],
  promise: (api: IApi) => api.dueDiligence.deleteFinancialsBankAccount(id),
})

export const RUN_BBC_REQUEST = `${prefix}/RUN_BBC_REQUEST`
export const RUN_BBC_SUCCESS = `${prefix}/RUN_BBC_SUCCESS`
export const RUN_BBC_FAILURE = `${prefix}/RUN_BBC_FAILURE`

export const runBBC: CommonPromiseAction = (id: string) => ({
  type: [RUN_BBC_REQUEST, RUN_BBC_SUCCESS, RUN_BBC_FAILURE],
  promise: (api: IApi) => api.dueDiligence.runBBC(id),
})

const REFRESH_AUTO_REPORTING = createRequestTypes(prefix, 'REFRESH_AUTO_REPORTING')
export const [REFRESH_AUTO_REPORTING_REQUEST, REFRESH_AUTO_REPORTING_SUCCESS] =
  REFRESH_AUTO_REPORTING

export const refreshAutoReporting: CommonPromiseAction = (id: string, data: object) => ({
  type: REFRESH_AUTO_REPORTING,
  promise: (api: IApi) => api.dueDiligence.refreshAutoReporting(id, data),
})

export const REFRESH_COUNT = `${prefix}/REFRESH_COUNT`

export const refreshCount: CommonPromiseAction = () => ({
  type: REFRESH_COUNT,
})

const LIST_LCR_COMMENTARY = createRequestTypes(prefix, 'LIST_LCR_COMMENTARY')
export const [
  LIST_LCR_COMMENTARY_REQUEST,
  LIST_LCR_COMMENTARY_SUCCESS,
  LIST_LCR_COMMENTARY_FAILURE,
] = LIST_LCR_COMMENTARY

export const listLCRCommentary: CommonPromiseAction = (id: string, params: object) => ({
  type: LIST_LCR_COMMENTARY,
  promise: (api: IApi) => api.dueDiligence.listLCRCommentary(id, params),
  params,
})

const ADD_LCR_COMMENTARY = createRequestTypes(prefix, 'ADD_LCR_COMMENTARY')
export const [ADD_LCR_COMMENTARY_REQUEST, ADD_LCR_COMMENTARY_SUCCESS, ADD_LCR_COMMENTARY_FAILURE] =
  ADD_LCR_COMMENTARY

export const addLCRCommentary: CommonPromiseAction = (id: string, params: object) => ({
  type: ADD_LCR_COMMENTARY,
  promise: (api: IApi) => api.dueDiligence.addLCRCommentary(id, params),
  params,
})

const UPDATE_LCR_COMMENTARY = createRequestTypes(prefix, 'UPDATE_LCR_COMMENTARY')
export const [
  UPDATE_LCR_COMMENTARY_REQUEST,
  UPDATE_LCR_COMMENTARY_SUCCESS,
  UPDATE_LCR_COMMENTARY_FAILURE,
] = UPDATE_LCR_COMMENTARY

export const updateLCRCommentary: CommonPromiseAction = (
  id: string,
  lcrCommentaryId: string,
  params: object,
) => ({
  type: UPDATE_LCR_COMMENTARY,
  promise: (api: IApi) => api.dueDiligence.updateLCRCommentary(id, lcrCommentaryId, params),
  params,
})

const DELETE_LCR_COMMENTARY = createRequestTypes(prefix, 'DELETE_LCR_COMMENTARY')
export const [
  DELETE_LCR_COMMENTARY_REQUEST,
  DELETE_LCR_COMMENTARY_SUCCESS,
  DELETE_LCR_COMMENTARY_FAILURE,
] = DELETE_LCR_COMMENTARY

export const deleteLCRCommentary: CommonPromiseAction = (
  id: string,
  lcrCommentaryId: string,
  params: object,
) => ({
  type: DELETE_LCR_COMMENTARY,
  promise: (api: IApi) => api.dueDiligence.deleteLCRCommentary(id, lcrCommentaryId),
  params,
})

const GET_HISTORICAL_LINE_TURN = createRequestTypes(prefix, 'GET_HISTORICAL_LINE_TURN')
export const [
  GET_HISTORICAL_LINE_TURN_REQUEST,
  GET_HISTORICAL_LINE_TURN_SUCCESS,
  GET_HISTORICAL_LINE_TURN_FAILURE,
] = GET_HISTORICAL_LINE_TURN

export const getHistoricalLineTurn: CommonPromiseAction = (id: string, params: object) => ({
  type: GET_HISTORICAL_LINE_TURN,
  promise: (api: IApi) => api.dueDiligence.getHistoricalLineTurn(id, params),
  params,
})

const GET_HISTORICAL_LINE_TURN_OPTIONS = createRequestTypes(
  prefix,
  'GET_HISTORICAL_LINE_TURN_OPTIONS',
)
export const [
  GET_HISTORICAL_LINE_TURN_OPTIONS_REQUEST,
  GET_HISTORICAL_LINE_TURN_OPTIONS_SUCCESS,
  GET_HISTORICAL_LINE_TURN_OPTIONS_FAILURE,
] = GET_HISTORICAL_LINE_TURN_OPTIONS

export const getHistoricalLineTurnOptions: CommonPromiseAction = (id: string) => ({
  type: GET_HISTORICAL_LINE_TURN_OPTIONS,
  promise: (api: IApi) => api.dueDiligence.getHistoricalLineTurnOptions(id),
})

const GET_DEFAULT_NOTES_OPTIONS = createRequestTypes(prefix, 'GET_DEFAULT_NOTES_OPTIONS')
export const [GET_DEFAULT_NOTES_OPTIONS_REQUEST] = GET_DEFAULT_NOTES_OPTIONS

export const getDefaultNotesOptions: CommonPromiseAction = () => ({
  type: GET_DEFAULT_NOTES_OPTIONS,
  promise: (api: IApi) => api.dueDiligence.getDefaultNotesOptions(),
})

const LIST_DEFAULT_NOTES = createRequestTypes(prefix, 'LIST_DEFAULT_NOTES')

export const [LIST_DEFAULT_NOTES_REQUEST] = LIST_DEFAULT_NOTES

export const listDefaultNotes: CommonPromiseAction = (params: object = {}) => ({
  type: LIST_DEFAULT_NOTES,
  promise: (api: IApi) => api.dueDiligence.listDefaultNotes(params),
  params,
})

const CREATE_DEFAULT_NOTE = createRequestTypes(prefix, 'CREATE_DEFAULT_NOTE')

export const [CREATE_DEFAULT_NOTE_REQUEST] = CREATE_DEFAULT_NOTE

export const createDefaultNote: CommonPromiseAction = (data: object) => ({
  type: CREATE_DEFAULT_NOTE,
  promise: (api: IApi) => api.dueDiligence.createDefaultNote(data),
})

const UPDATE_DEFAULT_NOTE = createRequestTypes(prefix, 'UPDATE_DEFAULT_NOTE')

export const [UPDATE_DEFAULT_NOTE_REQUEST] = UPDATE_DEFAULT_NOTE

export const updateDefaultNote: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_DEFAULT_NOTE,
  promise: (api: IApi) => api.dueDiligence.updateDefaultNote(id, data),
})

const DELETE_DEFAULT_NOTE = createRequestTypes(prefix, 'DELETE_DEFAULT_NOTE')

export const [DELETE_DEFAULT_NOTE_REQUEST] = DELETE_DEFAULT_NOTE

export const deleteDefaultNote: CommonPromiseAction = (id: string, params?: object) => ({
  type: DELETE_DEFAULT_NOTE,
  promise: (api: IApi) => api.dueDiligence.deleteDefaultNote(id, params),
  params,
})
