import Base from './base'

export default class Notes extends Base {
  add(params: object) {
    return this.apiClient.post('notes', params)
  }
  list(id: string, params: object) {
    return this.apiClient.get(`notes/${id}`, params)
  }
  delete(id: string, params?: object) {
    return this.apiClient.delete(`notes/${id}`, params)
  }
  update(id: string, params: object) {
    return this.apiClient.put(`notes/${id}`, params)
  }

  listSuggestions(data: object = {}) {
    return this.apiClient.get('notes/suggestions', data)
  }

  summarize(id: string, params: object) {
    return this.apiClient.get(`notes/${id}/summarize`, params)
  }

  addTags(params: object) {
    return this.apiClient.post('notes/tags', params)
  }
  deleteTags(id: string, data: object) {
    return this.apiClient.delete(`notes/tags/${id}`, data)
  }
}
