import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import cn from 'classnames'

import styles from './../NotesContainer.module.scss'

import Card from '../../Common/Card'
import AddNote from './../AddNote'
import { handleStopPropagation } from '../../../helpers/helpers'
import { INote } from '@common/interfaces/notes'

interface IProps {
  parent: INote
  hideReply?: () => void
  className?: string
  noteEditorFlex?: boolean
  isDiligence?: boolean
  listNotes?: (data: any) => void
  shouldFocusReplyInput?: boolean
}

const AddComment = ({
  parent,
  hideReply,
  className,
  noteEditorFlex = false,
  isDiligence,
  listNotes,
  shouldFocusReplyInput,
}: IProps) => {
  const isFlag = useMemo(() => parent?.flagId, [parent])
  return (
    <Card
      noHeaderMargin
      withBorder={false}
      className={cn(styles.commentContainer, className)}
      onClick={handleStopPropagation}
    >
      <Grid container spacing={0} alignItems="flex-start" rowSpacing={0}>
        <Grid item xs={12} justifyContent="center">
          <AddNote
            parentId={parent.id}
            placeholder={isFlag ? 'Add a comment' : 'Add a reply'}
            hideReply={hideReply}
            noteEditorFlex={noteEditorFlex}
            isComment
            isDiligence={isDiligence}
            listNotes={listNotes}
            shouldFocusEditorInput={shouldFocusReplyInput}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default AddComment
