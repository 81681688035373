import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './BBCIneligibleARDetailsInvoices.module.scss'
import genericSs from '@styles/generic.module.scss'
import MultiselectRow from '../MultiselectRow'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import { IARIneligibleStatsDetailsInvoice, IClientCollateral } from '@common/interfaces/bbc'
import { debounceEventHandler, formatDate, formatPrice, calculateGrowthPercentage } from '../../helpers/helpers'
import {
  AR_INELIGIBLE_STATS_DETAILS_INVOICES_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'
import useSummaryRow from '../../hooks/useSummaryRow'
import useTable from '../../hooks/useTable'
import GrowthDisplay from '../Common/GrowthDisplay'

interface IProps {
  arDetailStats: IARIneligibleStatsDetailsInvoice[]
  clientCollaterals: IClientCollateral[]
  listArIneligibleStatsDetailsInvoices: (id: string, data: object) => void
  filters: any
  label: string
  debtor: string
  displayGrowth?: boolean
}

const calculateGrowth = (row: IARIneligibleStatsDetailsInvoice, currentIndex: number) => {
  if (!row) {
    return undefined;
  }
  
  const currentKey = `value_${currentIndex}` as keyof typeof row;
  const nextKey = `value_${currentIndex + 1}` as keyof typeof row;
  
  if (!(currentKey in row) || !(nextKey in row)) {
    return undefined;
  }
  
  const currentValue = Number(row[currentKey] || 0);
  const nextValue = Number(row[nextKey] || 0);
  
  return calculateGrowthPercentage(currentValue, nextValue);
};

const BBCIneligibleARDetailsInvoices = ({
  arDetailStats,
  clientCollaterals,
  listArIneligibleStatsDetailsInvoices,
  filters,
  label,
  debtor,
  displayGrowth = false,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)

  const {
    handleSelectRow,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
  } = useTable({
    tableId: 'ar-ineligible-stats-details-invoices',
    sortDefault: {
      field: 'invoice',
      direction: 'ASC',
    },
  })

  const debounceListArIneligibleStatsDetailsInvoices = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listArIneligibleStatsDetailsInvoices(id, {
          ...data,
          label,
          debtor,
          nestedRows: {
            keys: ['label', 'debtor'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, listArIneligibleStatsDetailsInvoices, label, debtor],
  )

  useEffect(() => {
    !arDetailStats &&
      debounceListArIneligibleStatsDetailsInvoices({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices, arDetailStats])

  useEffect(() => {
    debounceListArIneligibleStatsDetailsInvoices({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices])

  const loadMore = useCallback(() => {
    debounceListArIneligibleStatsDetailsInvoices({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(arDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices, arDetailStats])

  const totalRow = useSummaryRow(arDetailStats, activeItems, {
    sumFields: ['value_0', 'value_1', 'value_2', 'value_3', 'value_4'],
  })

  const totalCount = useMemo(() => arDetailStats?.[0]?.totalCount, [arDetailStats])

  const filtersConfig = useMemo(
    () =>
      AR_INELIGIBLE_STATS_DETAILS_INVOICES_FILTERS_CONFIG.filter(
        (filter) =>
          !filter.field.startsWith('value_') ||
          !!clientCollaterals[parseInt(filter.field.replace('value_', ''))],
      ).map((filter) => {
        if (!filter.field.startsWith('value_') || filter.field === 'value_0') {
          return filter
        }

        const index = +filter.field.replace('value_', '')
        return {
          ...filter,
          title: formatDate(clientCollaterals[index].recordDate),
        }
      }),
    [clientCollaterals],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          >
            <TableCell />
          </TableFiltersRow>
        </TableHead>
        <TableBody
          id={`scrollableIneligibleARDetailsInvoices-${label}-${debtor}`}
          className={styles.scrollableDiv}
        >
          {isLoading ? (
            <TableLoader columnsCount={clientCollaterals.length + 1} />
          ) : (
            <InfiniteScroll
              dataLength={arDetailStats?.length || 0}
              next={loadMore}
              hasMore={arDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={clientCollaterals.length + 1} rowsCount={1} />}
              scrollableTarget={`scrollableIneligibleARDetailsInvoices-${label}-${debtor}`}
            >
              {arDetailStats?.map((row, index) => (
                <TableRow
                  key={row.invoice}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell />
                  <TableCell className={cn(genericSs.tableTextLeft, genericSs.textCapitalize)}>
                    {row.invoice}
                  </TableCell>
                  {clientCollaterals.map((_, key) => (
                    <TableCell key={key} className={cn(genericSs.tableTextRight, {
                      [genericSs.cellWithGrowthChip]: displayGrowth,
                    })}>
                      $ {formatPrice(row[`value_${key}` as keyof IARIneligibleStatsDetailsInvoice])}
                      {displayGrowth && key < clientCollaterals.length - 1 && (
                        <GrowthDisplay value={calculateGrowth(row, key)} />
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
          <MultiselectRow activeItems={activeItems}>
            <TableCell />
            {clientCollaterals.map((_, index) => (
              <TableCell key={index} className={cn(genericSs.tableTextRight, {
                [genericSs.cellWithGrowthChip]: displayGrowth,
              })}>
                ${' '}
                {formatPrice(
                  totalRow?.[`value_${index}` as keyof IARIneligibleStatsDetailsInvoice],
                )}
                {displayGrowth && index < clientCollaterals.length - 1 && totalRow && (
                  <GrowthDisplay 
                    value={calculateGrowth(totalRow, index)} 
                  />
                )}
              </TableCell>
            ))}
          </MultiselectRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCIneligibleARDetailsInvoices
